export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const NOTIFICATION_LOAD = "NOTIFICATION_LOAD";
export const NOTIFICATION_LOAD_ALL = "NOTIFICATION_LOAD_ALL"
export const NOTIFICATION_LOAD_DETAILS = "NOTIFICATION_LOAD_DETAILS";
export const NOTIFICATION_LOAD_ERROR = "NOTIFICATION_LOAD_ERROR";
export const NOTIFICATION_DELETE = "NOTIFICATION_DELETE";


export const SHOW_NOTIFICATIONS_MESSAGE = "SHOW_NOTIFICATIONS_MESSAGE";
export const HIDE_NOTIFICATION_MESSAGE = "HIDE_NOTIFICATION_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const EDIT_NOTIFICATION = "EDIT_NOTIFICATION";


export const GET_NOTIFICATION_DETAILS = 'GET_NOTIFICATION_DETAILS';
export const SET_NOTIFICATION_DETAILS = 'SET_NOTIFICATION_DETAILS';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_DETAILS = 'UPDATE_NOTIFICATION_DETAILS';


////////////////////////////// Tips ////////////////////////////////////////////
export const TIPS_LOAD = "TIPS_LOAD";
export const TIPS_LOAD_ALL = "TIPS_LOAD_ALL" ;
export const TIPS_DELETE = "TIPS_DELETE" ;
export const CREATE_TIP = "CREATE_TIP" ;