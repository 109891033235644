import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  REWARDS_LOAD_ALL,
  REWARDS_LOAD,
  REWARDS_DELETE,
  CREATE_REWARD
} from '../constants/Reward';
import {
    get_ALL_REWARDS,
    delete_REWARD,
    createREWARD
} from "../actions/Reward";

import service from '../../auth/blackApple';

///////////////////////////////// Rewards //////////////////////////////////

export function* getREWARDS() {
  yield takeEvery(REWARDS_LOAD, function* () {
	try {
		const REWARD = yield call(() => {
          return service().get("reward/listReward")         
        });
        let {status, message, data} = REWARD.data;
		if (!status) {
        //   yield put(showNOTIFICATIONMessage(message));
            alert(message);
		} else {
            yield put(get_ALL_REWARDS(data));
        }
		} catch (err) {
            alert(err);
            // yield put(showNOTIFICATIONMessage(err));
		}
	});
}

export function* createREWARDs() {
  yield takeEvery(CREATE_REWARD, function* ({payload}) {
	
		try {

			const REWARD = yield call(() => {

          return service().post("reward/addReward",payload)
                
        });

        let {status, message, data} = REWARD.data;

			if (!status) {

        //   yield put(showNOTIFICATIONMessage(message));
        alert(message);
                
			} else {
                alert(message);

        //   yield put(showNOTIFICATIONMessage(message));
                
      }
            
		} catch (err) {
        
    //   yield put(showNOTIFICATIONMessage(err.message));
    alert(err.message);
            
		}
	});
}

export function* deleteREWARD() {
   
  yield takeEvery(REWARDS_DELETE, function* ({payload}) {
		
		try {

			const REWARD = yield call(() => {

          return service().delete("site/notifications/delete-REWARD", {data: {'id': payload}})
                
      });

      let {status, message, data} = REWARD.data;

			if (!status) {
        alert(message)

        //   yield put(showNOTIFICATIONMessage(message));
                
			} else {
        
        // yield put(showNOTIFICATIONMessage(message));
        alert(message)
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err));

            // yield put(showNOTIFICATIONMessage(err));
            
		}
	});
}

export default function* rootSaga() {
  yield all([
        fork(getREWARDS),
        fork(createREWARDs),
        fork(deleteREWARD),
  ]);
}
