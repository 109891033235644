import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  CRYPTO_LOAD,
  CREATE_CRYPTO,
  CRYPTO_DELETE,
  EDIT_CRYPTO,
  GET_CRYPTO_DETAILS,
  UPDATE_CRYPTO
} from '../constants/Crypto';
import {
    get_all_CRYPTO,
    showCRYPTOMessage,
    createCRYPTO,
    setCRYPTODetails
} from "../actions/Crypto";

import service from '../../auth/FetchInterceptor'

export function* getAllCryptos() {
   
  yield takeEvery(CRYPTO_LOAD, function* () {
		
		try {

			const CRYPTO = yield call(() => {

          return service().get("crypto/all")
                
      });

      let {status, message, data} = CRYPTO.data;

			if (!status) {

          yield put(showCRYPTOMessage(message));
                
			} else {
        yield put(get_all_CRYPTO(data));
                
      }
            
		} catch (err) {
        yield put(showCRYPTOMessage(err));
            
		}
	});
}

export function* createCRYPTOs() {
  yield takeEvery(CREATE_CRYPTO, function* ({payload}) {
	
		try {

			const CRYPTO = yield call(() => {

                return service().post("crypto/create",payload)
                
            });

            let {status, message, data} = CRYPTO.data;

			if (!status) {

          yield put(showCRYPTOMessage(message));
                
			} else {

          yield put(showCRYPTOMessage(message));
                
      }
            
		} catch (err) {
        
      yield put(showCRYPTOMessage(err.message));
            
		}
	});
}

export function* editCRYPTOs() {
  yield takeEvery(EDIT_CRYPTO, function* ({payload}) {
	
		try {

    //   const config = {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // };

			const CRYPTO = yield call(() => {

                return service().put("crypto/update",payload)
                
            });

            let {status, message, data} = CRYPTO.data;


			if (!status) {

          yield put(showCRYPTOMessage(message));
                
			} else {


          yield put(showCRYPTOMessage(message));
                
      }
            
		} catch (err) {
        
      yield put(showCRYPTOMessage(err));
            
		}
	});
}

export function* deleteCRYPTOs() {
   
  yield takeEvery(CRYPTO_DELETE, function* ({payload}) {
		
		try {

			const CRYPTO = yield call(() => {

          return service().delete("crypto/delete", {data: {'currency': payload}})
                
      });

      let {status, message, data} = CRYPTO.data;

			if (!status) {

          yield put(showCRYPTOMessage(message));
                
			} else {
        
        yield put(showCRYPTOMessage(message));
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err.message));

            yield put(showCRYPTOMessage(err));
            
		}
	});
}

export function* getCRYPTODetails() {
   
  yield takeEvery(GET_CRYPTO_DETAILS, function* ({payload}) {
		
		try {

			const CRYPTO = yield call(() => {

          return service().get(`crypto/${payload}`)
                
      });

      let {status, message, data} = CRYPTO.data;

			if (!status) {

          yield put(showCRYPTOMessage(message));
                
			} else {
    
        yield put(setCRYPTODetails(data));
                
      }
            
		} catch (err) {
        yield put(showCRYPTOMessage(err.message));
            
		}
	});
}

export function* updateCRYPTO() {
  yield takeEvery(UPDATE_CRYPTO, function* ({payload}) {
	
		try {

			const CRYPTO = yield call(() => {

          return service().put("crypto/update",payload)
                
      });

      let {status, message, data} = CRYPTO.data;

			if (!status) {

          yield put(showCRYPTOMessage(message));
                
			} else {

          yield put(showCRYPTOMessage(message));
                
      }
            
		} catch (err) {
        
      yield put(showCRYPTOMessage(err.message));
            
		}
	});
}

export default function* rootSaga() {
  yield all([
        fork(getAllCryptos),
        fork(createCRYPTOs),
        fork(deleteCRYPTOs),
        fork(editCRYPTOs),
        fork(getCRYPTODetails),
        fork(updateCRYPTO)
  ]);
}
