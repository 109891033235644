import {
    SHOW_LOADING,
    REWARDS_LOAD_ALL,
    REWARDS_LOAD,
    REWARDS_DELETE,
    CREATE_REWARD

} from '../constants/Reward';

///////////////////////// Rewards ////////////////////////////////////////////

export const get_REWARDSLOAD = () => {
    return {
        type: REWARDS_LOAD
      }
  };

  export const get_ALL_REWARDS = (REWARDs) => {
    return {
        type: REWARDS_LOAD_ALL,
        payload: REWARDs
      }
  };

  export const delete_REWARD = (id) => {
    return {
        type: REWARDS_DELETE,
        payload: id
      }
  };

  export const createREWARD = (REWARD) => {
    return {
        type: CREATE_REWARD,
        payload: REWARD
      }
  };

  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  