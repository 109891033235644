import {
    QUESTION_LOAD_ALL,
    SHOW_QUESTIONS_MESSAGE,
    HIDE_QUESTION_MESSAGE,
	SHOW_LOADING,
	CREATE_QUESTION,
	EDIT_QUESTION,
	SET_QUESTION_DETAILS,
	UPDATE_QUESTION_DETAILS
} from '../constants/Question';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  questions: [],
  questionDetails: null,
  form: {}
}

const question = (state = initState, action) => {
	switch (action.type) {
		case QUESTION_LOAD_ALL:
			return {
				...state,
				loading: false,
				questions: action.payload
			}
		
		case SHOW_QUESTIONS_MESSAGE: 
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_QUESTION_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}

		case SET_QUESTION_DETAILS:
			return {
				...state,
				loading: false,
				questionDetails: action.payload
			}

		default:
			return state;
	}
}

export default question;