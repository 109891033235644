import {
    QUESTION_LOAD,
    SHOW_LOADING,
    SHOW_QUESTIONS_MESSAGE,
    HIDE_QUESTION_MESSAGE,
    CREATE_QUESTION,
    EDIT_QUESTION,
    QUESTION_LOAD_ALL,
    QUESTION_DELETE,
    GET_QUESTION_DETAILS,
    SET_QUESTION_DETAILS,
    UPDATE_QUESTION,
    UPDATE_QUESTION_DETAILS
} from '../constants/Question';  
  export const get_QUESTIONs = (course_id) => {
    return {
        type: QUESTION_LOAD,
        payload: course_id
      }
  };

  export const delete_QUESTION = (id) => {
    return {
        type: QUESTION_DELETE,
        payload: id
      }
  };

  export const get_all_QUESTION = (QUESTIONs) => {
    return {
        type: QUESTION_LOAD_ALL,
        payload: QUESTIONs
      }
  };

  export const createQUESTION = (QUESTION) => {
    return {
        type: CREATE_QUESTION,
        payload: QUESTION
      }
  };

  export const editQUESTION = (QUESTION) => {
    return {
        type: EDIT_QUESTION,
        payload: QUESTION
      }
  };

  export const updateQUESTION = (QUESTION) => {
    return {
        type: UPDATE_QUESTION,
        payload: QUESTION
      }
  };

  export const getQUESTIONDetails = (QUESTION) => {
    return {
        type: GET_QUESTION_DETAILS,
        payload: QUESTION
      }
  };

  export const setQUESTIONDetails = (QUESTION) => {
    return {
        type: SET_QUESTION_DETAILS,
        payload: QUESTION
      }
  };

  export const showQUESTIONMessage = (message) => {
    return {
      type: SHOW_QUESTIONS_MESSAGE,
      message
    };
  };

  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideQUESTIONMessage = () => {
    return {
      type: HIDE_QUESTION_MESSAGE,
    };
  };

  