import axios from 'axios';
import jwt from "jsonwebtoken";
import AES from "crypto-js/aes";
import { API_BASE_URL } from 'configs/AppConfig';
import history from '../history';
import { AUTH_TOKEN } from 'redux/constants/Auth';
import { notification } from 'antd';

export default (captcha = null) => {

	const {
		REACT_APP_X_TOKEN,
		REACT_APP_SECRET_TOKEN
	} = process.env;
	
	const signXToken = jwt.sign({ time : Date.now()}, REACT_APP_X_TOKEN , {expiresIn :  '10s' })
	const encryptAES = AES.encrypt(`${Date.now()}`, REACT_APP_SECRET_TOKEN).toString();
	
	const service = axios.create({
	  baseURL: API_BASE_URL,
	  timeout: 60000
	})
	
	// Config
	const ENTRY_ROUTE = '/auth/login'
	const TOKEN_PAYLOAD_KEY = 'authorization'
	const PUBLIC_REQUEST_KEY = 'public-request'
	
	// API Request interceptor
	service.interceptors.request.use(config => {
		const jwtToken = localStorage.getItem(AUTH_TOKEN)
	
		config.headers["x-token"] = signXToken;
		config.headers["secret-token"] = encryptAES;
		if(captcha){
			config.headers["gRecaptcha"] = captcha;
		}
		
	  if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
	  }
	
	  return config
	}, error => {
		// Do something with request error here
		notification.error({
			message: 'Error'
		})
	  Promise.reject(error)
	})
	
	// API respone interceptor
	service.interceptors.response.use( (response) => {
	  //response.data.data.status = response.data.status;
		return response
	}, (error) => {
	
		let notificationParam = {
			message: ''
		}
		
		// Remove token and redirect 
		if (error.response.status === 400 || error.response.status === 403) {
			notificationParam.message = 'Authentication Fail'
			notificationParam.description = 'Please login again'
			localStorage.removeItem(AUTH_TOKEN)
			history.push(ENTRY_ROUTE)
			window.location.reload();
		}
	
		if (error.response.status === 404) {
			notificationParam.message = 'Not Found'
		}
	
		if (error.response.status === 500) {
			notificationParam.message = 'Internal Server Error'
		}
		
		if (error.response.status === 508) {
			notificationParam.message = 'Time Out'
		}
	
		notification.error(notificationParam)
	
		return Promise.reject(error);
	});

	return service;
}

// import axios from "axios";

// export default (history = null) => {
//   const baseURL = 'https://earnathon.herokuapp.com/api/v1/';//process.env.REACT_APP_BACKEND_URL
//   let headers = {};

//   if (localStorage.token) {
//     headers.Authorization = `Bearer ${localStorage.token}`;
//   }

//   const axiosInstance = axios.create({
//     baseURL: baseURL,
//     headers,
//   });

//   axiosInstance.interceptors.response.use(
//     (response) =>
//       new Promise((resolve, reject) => {
//         resolve(response);
//       }),
//     (error) => {
//       if (!error.response) {
//         return new Promise((resolve, reject) => {
//           reject(error);
//         });
//       }

//       if (error.response.status === 403) {
//         localStorage.removeItem("token");

//         if (history) {
//           history.push("/login");
//         } else {
//           window.location = "/login";
//         }
//       } else {
//         return new Promise((resolve, reject) => {
//           reject(error);
//         });
//       }
//     }
//   );

//   return axiosInstance;
// };
