import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
    GET_KPI,
    GET_COURSE_KPI
} from '../constants/Kpi';
import {
    setKpi,
    setCourseKpi,
    setError
} from "../actions/Kpi";

import service from '../../auth/FetchInterceptor'

export function* getKpi() {
   
  yield takeEvery(GET_KPI, function* () {
		
		try {


			const kpi = yield call(() => {

            return service().get("kpi")
                
            });

            let {status, message, data} = kpi.data;

            if (!status) {

                yield put(setError(message));
                    
            } else {

                yield put(setKpi(data));
                    
            }
            
		} catch (err) {

            alert(err);
            yield put(setError(err));
            
		}
	});
}

export function* getCourseKpi() {
   
    yield takeEvery(GET_COURSE_KPI, function* ({payload}) {
          
          try {
              const kpi = yield call(() => {
              return service().get(`kpi/course-kpi/${payload}`)   
              });
              let {status, message, data} = kpi.data;
  
              if (!status) {
                  yield put(setError(message));      
              } else {
                  yield put(setCourseKpi(data));       
              } 
          } catch (err) {
              alert(err);
              yield put(setError(err));
              
          }
      });
  }

export default function* rootSaga() {
  yield all([
        fork(getKpi),
        fork(getCourseKpi)
  ]);
}
