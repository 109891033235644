export const CRYPTO_LOADING = "CRYPTO_LOADING";
export const CRYPTO_LOAD = "CRYPTO_LOAD";
export const CRYPTO_LOAD_ALL = "CRYPTO_LOAD_ALL"
export const CRYPTO_LOAD_DETAILS = "CRYPTO_LOAD_DETAILS";
export const CRYPTO_LOAD_ERROR = "CRYPTO_LOAD_ERROR";
export const CRYPTO_DELETE = "CRYPTO_DELETE";


export const SHOW_CRYPTOS_MESSAGE = "SHOW_CRYPTOS_MESSAGE";
export const HIDE_CRYPTO_MESSAGE = "HIDE_CRYPTO_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const CREATE_CRYPTO = "CREATE_CRYPTO";
export const EDIT_CRYPTO = "EDIT_CRYPTO";


export const GET_CRYPTO_DETAILS = 'GET_CRYPTO_DETAILS';
export const SET_CRYPTO_DETAILS = 'SET_CRYPTO_DETAILS';
export const UPDATE_CRYPTO = 'UPDATE_CRYPTO';
export const UPDATE_CRYPTO_DETAILS = 'UPDATE_CRYPTO_DETAILS';