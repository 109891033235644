import {
    KPI_LOADING,
    KPI_SUCCESS,
    KPI_COURSE_SUCCESS,
    KPI_ERROR,
    KPI_SET_ERROR,
    GET_KPI,
    GET_COURSE_KPI
} from '../constants/Kpi';  
  export const get_kpi = () => {
    return {
        type: GET_KPI
      }
  };

  export const get_course_kpi = (course_id) => {
    return {
        type: GET_COURSE_KPI,
        payload: course_id
      }
  };


  export const setKpi = (kpi) => {
    return {
      type: KPI_SUCCESS,
      payload: kpi
    };
  };

  export const setCourseKpi = (kpi) => {
    return {
      type: KPI_COURSE_SUCCESS,
      payload: kpi
    };
  };

  export const showLoading = () => {
    return {
      type: KPI_LOADING,
    };
  };

  export const setError = (message) => {
    return {
      type: KPI_SET_ERROR,
      payload: message
    };
  };

  