import {
    KPI_LOADING,
    KPI_SUCCESS,
	KPI_COURSE_SUCCESS,
    KPI_SET_ERROR,

} from '../constants/Kpi';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  dashboard: {},
  courseKpi: {}
}

const kpi = (state = initState, action) => {
	switch (action.type) {
		case KPI_SUCCESS:
			return {
				...state,
				loading: false,
				dashboard: action.payload
			}

		case KPI_COURSE_SUCCESS:
			return {
				...state,
				loading: false,
				courseKpi: action.payload
			}
		case KPI_SET_ERROR: 
			return {
				...state,
				message: action.payload,
				loading: false,
			}
		case KPI_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		default:
			return state;
	}
}

export default kpi