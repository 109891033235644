import {
    CRYPTO_LOAD_ALL,
    SHOW_CRYPTOS_MESSAGE,
    HIDE_CRYPTO_MESSAGE,
	SHOW_LOADING,
	CREATE_CRYPTO,
	EDIT_CRYPTO,
	SET_CRYPTO_DETAILS,
	UPDATE_CRYPTO_DETAILS
} from '../constants/Crypto';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  data: [],
  cryptoDetails: null,
  form:{}
}

const CRYPTO = (state = initState, action) => {
	switch (action.type) {
		case CRYPTO_LOAD_ALL:
			return {
				...state,
				loading: false,
				data: action.payload
			}
		// case EDIT_CRYPTO:
		// case CREATE_CRYPTO:
		// 		return {
		// 			...state,
		// 			loading: false,
		// 			form: action.payload
		// 		}
		case SHOW_CRYPTOS_MESSAGE: 
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_CRYPTO_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}

		case SET_CRYPTO_DETAILS:
			return {
				...state,
				loading: false,
				cryptoDetails: action.payload
			}

		default:
			return state;
	}
}

export default CRYPTO