import {
    CRYPTO_LOAD,
    SHOW_LOADING,
    SHOW_CRYPTOS_MESSAGE,
    HIDE_CRYPTO_MESSAGE,
    CREATE_CRYPTO,
    EDIT_CRYPTO,
    CRYPTO_LOAD_ALL,
    CRYPTO_DELETE,
    GET_CRYPTO_DETAILS,
    SET_CRYPTO_DETAILS,
    UPDATE_CRYPTO,
    UPDATE_CRYPTO_DETAILS
} from '../constants/Crypto';  
  export const get_crypto = () => {
    return {
        type: CRYPTO_LOAD
      }
  };

  export const delete_crypto = (id) => {
    return {
        type: CRYPTO_DELETE,
        payload: id
      }
  };

  export const get_all_CRYPTO = (CRYPTOs) => {
    return {
        type: CRYPTO_LOAD_ALL,
        payload: CRYPTOs
      }
  };

  export const createCRYPTO = (CRYPTO) => {
    return {
        type: CREATE_CRYPTO,
        payload: CRYPTO
      }
  };

  export const editCRYPTO = (CRYPTO) => {
    return {
        type: EDIT_CRYPTO,
        payload: CRYPTO
      }
  };

  export const updateCRYPTO = (CRYPTO) => {
    return {
        type: UPDATE_CRYPTO,
        payload: CRYPTO
      }
  };

  export const getCRYPTODetails = (CRYPTO) => {
    return {
        type: GET_CRYPTO_DETAILS,
        payload: CRYPTO
      }
  };

  export const setCRYPTODetails = (CRYPTO) => {
    return {
        type: SET_CRYPTO_DETAILS,
        payload: CRYPTO
      }
  };

  export const showCRYPTOMessage = (message) => {
    return {
      type: SHOW_CRYPTOS_MESSAGE,
      message
    };
  };

  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideCRYPTOMessage = () => {
    return {
      type: HIDE_CRYPTO_MESSAGE,
    };
  };

  