import {
    NOTIFICATION_LOAD,
    SHOW_LOADING,
    SHOW_NOTIFICATIONS_MESSAGE,
    HIDE_NOTIFICATION_MESSAGE,
    CREATE_NOTIFICATION,
    EDIT_NOTIFICATION,
    NOTIFICATION_LOAD_ALL,
    NOTIFICATION_DELETE,
    GET_NOTIFICATION_DETAILS,
    SET_NOTIFICATION_DETAILS,
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_DETAILS,
    TIPS_LOAD_ALL,
    TIPS_LOAD,
    TIPS_DELETE,
    CREATE_TIP

} from '../constants/Notification';  
  export const get_NOTIFICATION = () => {
    return {
        type: NOTIFICATION_LOAD
      }
  };

  export const delete_NOTIFICATION = (id) => {
    return {
        type: NOTIFICATION_DELETE,
        payload: id
      }
  };

  export const get_all_NOTIFICATION = (NOTIFICATIONs) => {
    return {
        type: NOTIFICATION_LOAD_ALL,
        payload: NOTIFICATIONs
      }
  };

  export const createNOTIFICATION = (NOTIFICATION) => {
    return {
        type: CREATE_NOTIFICATION,
        payload: NOTIFICATION
      }
  };

  export const editNOTIFICATION = (NOTIFICATION) => {
    return {
        type: EDIT_NOTIFICATION,
        payload: NOTIFICATION
      }
  };

  export const updateNOTIFICATION = (NOTIFICATION) => {
    return {
        type: UPDATE_NOTIFICATION,
        payload: NOTIFICATION
      }
  };

  export const getNOTIFICATIONDetails = (NOTIFICATION) => {
    return {
        type: GET_NOTIFICATION_DETAILS,
        payload: NOTIFICATION
      }
  };

  export const setNOTIFICATIONDetails = (NOTIFICATION) => {
    return {
        type: SET_NOTIFICATION_DETAILS,
        payload: NOTIFICATION
      }
  };

  export const showNOTIFICATIONMessage = (message) => {
    return {
      type: SHOW_NOTIFICATIONS_MESSAGE,
      message
    };
  };

  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideNOTIFICATIONMessage = () => {
    return {
      type: HIDE_NOTIFICATION_MESSAGE,
    };
  };


  ///////////////////////// Tips ////////////////////////////////////////////

  export const get_TIPSLOAD = () => {
    return {
        type: TIPS_LOAD
      }
  };

  export const get_ALL_TIPS = (TIPs) => {
    return {
        type: TIPS_LOAD_ALL,
        payload: TIPs
      }
  };

  export const delete_TIP = (id) => {
    return {
        type: TIPS_DELETE,
        payload: id
      }
  };

  export const createTIP = (TIP) => {
    return {
        type: CREATE_TIP,
        payload: TIP
      }
  };

  