import {
    LESSON_LOAD,
    SHOW_LOADING,
    SHOW_LESSONS_MESSAGE,
    HIDE_LESSON_MESSAGE,
    CREATE_LESSON,
    EDIT_LESSON,
    LESSON_LOAD_ALL,
    LESSON_DELETE,
    GET_LESSON_DETAILS,
    SET_LESSON_DETAILS,
    UPDATE_LESSON,
    UPDATE_LESSON_DETAILS
} from '../constants/Lesson';  
  export const get_lessons = (course_id) => {
    return {
        type: LESSON_LOAD,
        payload: course_id
      }
  };

  export const delete_LESSON = (id) => {
    return {
        type: LESSON_DELETE,
        payload: id
      }
  };

  export const get_all_LESSON = (LESSONs) => {
    return {
        type: LESSON_LOAD_ALL,
        payload: LESSONs
      }
  };

  export const createLESSON = (LESSON) => {
    return {
        type: CREATE_LESSON,
        payload: LESSON
      }
  };

  export const editLESSON = (LESSON) => {
    return {
        type: EDIT_LESSON,
        payload: LESSON
      }
  };

  export const updateLESSON = (LESSON) => {
    return {
        type: UPDATE_LESSON,
        payload: LESSON
      }
  };

  export const getLESSONDetails = (LESSON) => {
    return {
        type: GET_LESSON_DETAILS,
        payload: LESSON
      }
  };

  export const setLESSONDetails = (LESSON) => {
    return {
        type: SET_LESSON_DETAILS,
        payload: LESSON
      }
  };

  export const showLESSONMessage = (message) => {
    return {
      type: SHOW_LESSONS_MESSAGE,
      message
    };
  };

  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideLESSONMessage = () => {
    return {
      type: HIDE_LESSON_MESSAGE,
    };
  };

  