import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import history from './history'
import {message, messages } from "antd";
import jwt_decode from 'jwt-decode';
import RefreshToken          from "./utils/refreshToken";
import isAuthenticated       from "./utils/isAuthenticated";


import {
	AUTH_TOKEN,
} from './redux/constants/Auth';

// const expireJwt = async () => {

//   if(isAuthenticated()){

//       let token =  localStorage.token;

//       let jwt = jwt_decode(token, {headers: true});

//       let current_time = Date.now().valueOf() / 1000;

//       if(jwt.exp < current_time){

//           await localStorage.removeItem(AUTH_TOKEN);
//           await localStorage.removeItem('admin');
//           await localStorage.clear();

//           history.push('/auth/login')

//           return false;
//       }

//   }


//   // var exp = jwt.exp * 1000;
//   // console.log(new Date(exp));
// }

const expireJwt = (history) => {

  if(isAuthenticated()){

      let token =  localStorage.token;

      //console.log(token);

      let jwt = jwt_decode(token, {headers: true});

      let current_time = Date.now().valueOf();

      // const jwtExp = jwt.exp - current_time ;

      // if(jwtExp < 1500 && jwtExp > 0){

      // }

      if(jwt.exp < current_time){

        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem('admin');
        localStorage.clear();

          history.push("/auth/login");
          message.open({
            type: 'error',
            content: 'Your Token Session Is Expired Please Sign In Again',
            duration: 5
          })

          return false;
      }else{
        console.log('session still active');
      }

  }
}

function App() {
  expireJwt(history);
  return (
    <div className="App">
      <Provider store={store}>
        <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
        <RefreshToken/>
          <Switch>
            <Route path="/" component={Views}/>
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
