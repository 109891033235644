export const COURSE_LOADING = "COURSE_LOADING";
export const COURSE_LOAD = "COURSE_LOAD";
export const COURSE_LOAD_ALL = "COURSE_LOAD_ALL"
export const COURSE_LOAD_DETAILS = "COURSE_LOAD_DETAILS";
export const COURSE_LOAD_ERROR = "COURSE_LOAD_ERROR";
export const COURSE_DELETE = "COURSE_DELETE";


export const SHOW_COURSES_MESSAGE = "SHOW_COURSES_MESSAGE";
export const HIDE_COURSE_MESSAGE = "HIDE_COURSE_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const CREATE_COURSE = "CREATE_COURSE";
export const EDIT_COURSE = "EDIT_COURSE";


export const GET_COURSE_DETAILS = 'GET_COURSE_DETAILS';
export const SET_COURSE_DETAILS = 'SET_COURSE_DETAILS';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const UPDATE_COURSE_DETAILS = 'UPDATE_COURSE_DETAILS';