export const LESSON_LOADING = "LESSON_LOADING";
export const LESSON_LOAD = "LESSON_LOAD";
export const LESSON_LOAD_ALL = "LESSON_LOAD_ALL"
export const LESSON_LOAD_DETAILS = "LESSON_LOAD_DETAILS";
export const LESSON_LOAD_ERROR = "LESSON_LOAD_ERROR";
export const LESSON_DELETE = "LESSON_DELETE";


export const SHOW_LESSONS_MESSAGE = "SHOW_LESSONS_MESSAGE";
export const HIDE_LESSON_MESSAGE = "HIDE_LESSON_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const CREATE_LESSON = "CREATE_LESSON";
export const EDIT_LESSON = "EDIT_LESSON";


export const GET_LESSON_DETAILS = 'GET_LESSON_DETAILS';
export const SET_LESSON_DETAILS = 'SET_LESSON_DETAILS';
export const UPDATE_LESSON = 'UPDATE_LESSON';
export const UPDATE_LESSON_DETAILS = 'UPDATE_LESSON_DETAILS';