import {
    COURSE_LOAD_ALL,
    SHOW_COURSES_MESSAGE,
    HIDE_COURSE_MESSAGE,
	SHOW_LOADING,
	CREATE_COURSE,
	EDIT_COURSE,
	SET_COURSE_DETAILS,
	UPDATE_COURSE_DETAILS
} from '../constants/Course';

const initState = {
  loading: true,
  message: '',
  showMessage: false,
  redirect: '',
  courses: [],
  courseDetails: null,
  form:{}
}

const course = (state = initState, action) => {
	switch (action.type) {
		case COURSE_LOAD_ALL:
			return {
				...state,
				loading: false,
				courses: action.payload
			}
		// case EDIT_COURSE:
		// case CREATE_COURSE:
		// 		return {
		// 			...state,
		// 			loading: false,
		// 			form: action.payload
		// 		}
		case SHOW_COURSES_MESSAGE: 
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_COURSE_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}

		case SET_COURSE_DETAILS:
			return {
				...state,
				loading: false,
				courseDetails: action.payload
			}

		default:
			return state;
	}
}

export default course