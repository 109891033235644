import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  NOTIFICATION_LOAD,
  CREATE_NOTIFICATION,
  NOTIFICATION_DELETE,
  EDIT_NOTIFICATION,
  GET_NOTIFICATION_DETAILS,
  UPDATE_NOTIFICATION,
  TIPS_LOAD_ALL,
  TIPS_LOAD,
  TIPS_DELETE,
  CREATE_TIP
} from '../constants/Notification';
import {
    get_all_NOTIFICATION,
    showNOTIFICATIONMessage,
    createNOTIFICATION,
    setNOTIFICATIONDetails,
    get_ALL_TIPS,
    delete_TIP,
    createTIP
} from "../actions/Notification";

import service from '../../auth/FetchInterceptor'

export function* getAllNOTIFICATIONs() {
   
  yield takeEvery(NOTIFICATION_LOAD, function* () {
		
		try {

			const NOTIFICATION = yield call(() => {

          return service().get("site/notifications")
                
      });

      let {status, message, data} = NOTIFICATION.data;

			if (!status) {

          yield put(showNOTIFICATIONMessage(message));
                
			} else {

        yield put(get_all_NOTIFICATION(data));
                
      }
            
		} catch (err) {
        yield put(showNOTIFICATIONMessage(err));
            
		}
	});
}

export function* createNOTIFICATIONs() {
  yield takeEvery(CREATE_NOTIFICATION, function* ({payload}) {
	
		try {

    //   const config = {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // };

			const NOTIFICATION = yield call(() => {

                return service().post("site/notifications/create",payload)
                
            });

            let {status, message, data} = NOTIFICATION.data;

			if (!status) {

          yield put(showNOTIFICATIONMessage(message));
                
			} else {

          yield put(showNOTIFICATIONMessage(message));
                
      }
            
		} catch (err) {
        
      yield put(showNOTIFICATIONMessage(err.message));
            
		}
	});
}

export function* editNOTIFICATIONs() {
  yield takeEvery(EDIT_NOTIFICATION, function* ({payload}) {
	
		try {

    //   const config = {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // };

			const NOTIFICATION = yield call(() => {

                return service().put("NOTIFICATION/update",payload)
                
            });

            let {status, message, data} = NOTIFICATION.data;

            alert(message);

			if (!status) {

          yield put(showNOTIFICATIONMessage(message));
                
			} else {

          alert('NOTIFICATION Updated');

          yield put(showNOTIFICATIONMessage(message));
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err));
        
      yield put(showNOTIFICATIONMessage(err));
            
		}
	});
}

export function* deleteNOTIFICATIONs() {
   
  yield takeEvery(NOTIFICATION_DELETE, function* ({payload}) {

    alert(payload);
		
		try {

			const NOTIFICATION = yield call(() => {

          return service().delete("site/notifications/delete", {data: {'id': payload}})
                
      });

      let {status, message, data} = NOTIFICATION.data;

			if (!status) {
        alert(message)

          yield put(showNOTIFICATIONMessage(message));
                
			} else {
        
        yield put(showNOTIFICATIONMessage(message));
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err));

            yield put(showNOTIFICATIONMessage(err));
            
		}
	});
}

export function* getNOTIFICATIONDetails() {
   
  yield takeEvery(GET_NOTIFICATION_DETAILS, function* ({payload}) {
		
		try {

			const NOTIFICATION = yield call(() => {

          return service().get(`NOTIFICATION/${payload}`)
                
      });

      let {status, message, data} = NOTIFICATION.data;

			if (!status) {

          yield put(showNOTIFICATIONMessage(message));
                
			} else {
    
        yield put(setNOTIFICATIONDetails(data));
                
      }
            
		} catch (err) {
        yield put(showNOTIFICATIONMessage(err));
            
		}
	});
}

export function* updateNOTIFICATION() {
  yield takeEvery(UPDATE_NOTIFICATION, function* ({payload}) {
	
		try {

			const NOTIFICATION = yield call(() => {

          return service().put("NOTIFICATION/update",payload)
                
      });

      let {status, message, data} = NOTIFICATION.data;

      alert(message);

			if (!status) {

          yield put(showNOTIFICATIONMessage(message));
                
			} else {

          yield put(showNOTIFICATIONMessage(message));
                
      }
            
		} catch (err) {
        
      yield put(showNOTIFICATIONMessage(err.message));
            
		}
	});
}

///////////////////////////////// Tips //////////////////////////////////

export function* getTIPS() {
   
  yield takeEvery(TIPS_LOAD, function* () {
		
		try {

			const TIP = yield call(() => {

          return service().get("site/notifications/list-tips")
                
      });

      let {status, message, data} = TIP.data;

			if (!status) {

          yield put(showNOTIFICATIONMessage(message));
                
			} else {

        yield put(get_ALL_TIPS(data));
                
      }
            
		} catch (err) {
        yield put(showNOTIFICATIONMessage(err));
            
		}
	});
}

export function* createTIPs() {
  yield takeEvery(CREATE_TIP, function* ({payload}) {
	
		try {

			const TIP = yield call(() => {

          return service().post("site/notifications/add-tip",payload)
                
        });

        let {status, message, data} = TIP.data;

			if (!status) {

          yield put(showNOTIFICATIONMessage(message));
                
			} else {

          yield put(showNOTIFICATIONMessage(message));
                
      }
            
		} catch (err) {
        
      yield put(showNOTIFICATIONMessage(err.message));
            
		}
	});
}

export function* deleteTIP() {
   
  yield takeEvery(TIPS_DELETE, function* ({payload}) {
		
		try {

			const TIP = yield call(() => {

          return service().delete("site/notifications/delete-tip", {data: {'id': payload}})
                
      });

      let {status, message, data} = TIP.data;

			if (!status) {
        alert(message)

          yield put(showNOTIFICATIONMessage(message));
                
			} else {
        
        // yield put(showNOTIFICATIONMessage(message));
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err));

            yield put(showNOTIFICATIONMessage(err));
            
		}
	});
}

export default function* rootSaga() {
  yield all([
        fork(getAllNOTIFICATIONs),
        fork(createNOTIFICATIONs),
        fork(deleteNOTIFICATIONs),
        fork(editNOTIFICATIONs),
        fork(getNOTIFICATIONDetails),
        fork(updateNOTIFICATION),
        fork(getTIPS),
        fork(createTIPs),
        fork(deleteTIP),
  ]);
}
