import {
    NOTIFICATION_LOAD_ALL,
    SHOW_NOTIFICATIONS_MESSAGE,
    HIDE_NOTIFICATION_MESSAGE,
	SHOW_LOADING,
	CREATE_NOTIFICATION,
	EDIT_NOTIFICATION,
	SET_NOTIFICATION_DETAILS,
	UPDATE_NOTIFICATION_DETAILS,
	TIPS_LOAD_ALL,
    TIPS_DELETE,
    CREATE_TIP
} from '../constants/Notification';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  notifications: [],
  tips:[],
  notificationDetails: null,
  form:{}
}

const Notification = (state = initState, action) => {
	switch (action.type) {
		case NOTIFICATION_LOAD_ALL:
			return {
				...state,
				loading: false,
				notifications: action.payload
			}
		// case EDIT_NOTIFICATION:
		// case CREATE_NOTIFICATION:
		// 		return {
		// 			...state,
		// 			loading: false,
		// 			form: action.payload
		// 		}
		case SHOW_NOTIFICATIONS_MESSAGE: 
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_NOTIFICATION_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}

		case SET_NOTIFICATION_DETAILS:
			return {
				...state,
				loading: false,
				notificationDetails: action.payload
			}

			//////// Tips ///////////

		case TIPS_LOAD_ALL:
			return {
				...state,
				loading: false,
				tips: action.payload
			}

		default:
			return state;
	}
}

export default Notification;