import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Course from './Course';
import Kpi from './Kpi';
import Lesson from './Lesson';
import Quiz from './Quiz';
import Question from './Question';
import Crypto from './Crypto';
import Notification from './Notification';
import Reward from './Reward';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Course(),
    Kpi(),
    Lesson(),
    Quiz(),
    Question(),
    Crypto(),
    Notification(),
    Reward()
  ]);
}
