import {
    QUIZ_LOAD_ALL,
    SHOW_QUIZS_MESSAGE,
    HIDE_QUIZ_MESSAGE,
	SHOW_LOADING,
	CREATE_QUIZ,
	EDIT_QUIZ,
	SET_QUIZ_DETAILS,
	UPDATE_QUIZ_DETAILS,
	QUIZ_RANDOM_REWARD_USERS
} from '../constants/Quiz';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  quizs: [],
  quizDetails: null,
  reward: null,
  form: {}
}

const quiz = (state = initState, action) => {
	switch (action.type) {
		case QUIZ_LOAD_ALL:
			return {
				...state,
				loading: false,
				quizs: action.payload
			}
		case QUIZ_RANDOM_REWARD_USERS:
			return {
				...state,
				reward: true,
			}
		
		case SHOW_QUIZS_MESSAGE: 
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false,
				reward: false
			}
		case HIDE_QUIZ_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}

		case SET_QUIZ_DETAILS:
			return {
				...state,
				loading: false,
				quizsDetails: action.payload
			}

		default:
			return state;
	}
}

export default quiz;