import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK,
	REFRESH_TOKEN
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated
} from "../actions/Auth";

import FirebaseService from 'services/FirebaseService'
import service from '../../auth/FetchInterceptor'
import unAuth from '../../auth/unauth'
import axios from 'axios';

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
	  
		const {email, password, captcha} = payload;
		
		try {
			const user = yield call(() => {
				return unAuth(captcha).post("auth/sign-in", {user: email, password})
			});

			let {status, message, data} = user.data;

			if (!status) {
				yield put(showAuthMessage(message));
			} else {
				if(!data.isAdmin){
					yield put(showAuthMessage('You are not authorized yet'));
				}else{
					console.log(data.token);
					localStorage.setItem(AUTH_TOKEN, data.token);
					localStorage.setItem('admin', JSON.stringify(data));
					yield put(authenticated(data.token));
				}
			}
		} catch (err) {
			yield put(showAuthMessage(err.message));
		}
	});
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signUpEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signUpSuccess(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* refreshToken() {
	yield takeEvery(REFRESH_TOKEN, function* ({payload}) {
		  
		  try {
			  const res = yield call(() => {
				  return service().post(`user/refresh-token`)
			  });
  
			  let {status, message, data} = res.data;
  
			  if (status) {
				localStorage.setItem(AUTH_TOKEN, data.token);
			  }
			  else{
				  console.log(message)
			  }
		  } catch (err) {
			console.log("refresh token error", err);
		  }
	  });
  }

  export const refreshtoken = async () => {
	try {
		const res = await service().post(`user/refresh-token`);
		let {status, message, data} = res.data;
		if (status) {
		  localStorage.setItem(AUTH_TOKEN, data.token);
		}
		else{
			console.log(message)
		}
	} catch (err) {
	  console.log("refresh token error", err);
	}
  }

export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook),
		fork(refreshToken)
  ]);
}
