import {
    LESSON_LOAD_ALL,
    SHOW_LESSONS_MESSAGE,
    HIDE_LESSON_MESSAGE,
	SHOW_LOADING,
	CREATE_LESSON,
	EDIT_LESSON,
	SET_LESSON_DETAILS,
	UPDATE_LESSON_DETAILS
} from '../constants/Lesson';

const initState = {
  loading: true,
  message: '',
  showMessage: false,
  redirect: '',
  lessons: [],
  lessonDetails: null,
  form: {}
}

const lesson = (state = initState, action) => {
	switch (action.type) {
		case LESSON_LOAD_ALL:
			return {
				...state,
				loading: false,
				lessons: action.payload
			}
		
		case SHOW_LESSONS_MESSAGE: 
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_LESSON_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}

		case SET_LESSON_DETAILS:
			return {
				...state,
				loading: false,
				lessonDetails: action.payload
			}

		default:
			return state;
	}
}

export default lesson;