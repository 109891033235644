import {
    COURSE_LOAD,
    SHOW_LOADING,
    SHOW_COURSES_MESSAGE,
    HIDE_COURSE_MESSAGE,
    CREATE_COURSE,
    EDIT_COURSE,
    COURSE_LOAD_ALL,
    COURSE_DELETE,
    GET_COURSE_DETAILS,
    SET_COURSE_DETAILS,
    UPDATE_COURSE,
    UPDATE_COURSE_DETAILS
} from '../constants/Course';  
  export const get_course = () => {
    return {
        type: COURSE_LOAD
      }
  };

  export const delete_course = (id) => {
    return {
        type: COURSE_DELETE,
        payload: id
      }
  };

  export const get_all_course = (courses) => {
    return {
        type: COURSE_LOAD_ALL,
        payload: courses
      }
  };

  export const createCourse = (course) => {
    return {
        type: CREATE_COURSE,
        payload: course
      }
  };

  export const editCourse = (course) => {
    return {
        type: EDIT_COURSE,
        payload: course
      }
  };

  export const updateCourse = (course) => {
    return {
        type: UPDATE_COURSE,
        payload: course
      }
  };

  export const getCourseDetails = (course) => {
    return {
        type: GET_COURSE_DETAILS,
        payload: course
      }
  };

  export const setCourseDetails = (course) => {
    return {
        type: SET_COURSE_DETAILS,
        payload: course
      }
  };

  export const showCourseMessage = (message) => {
    return {
      type: SHOW_COURSES_MESSAGE,
      message
    };
  };

  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideCourseMessage = () => {
    return {
      type: HIDE_COURSE_MESSAGE,
    };
  };

  