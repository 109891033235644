export const QUIZ_LOADING = "QUIZ_LOADING";
export const QUIZ_LOAD = "QUIZ_LOAD";
export const QUIZ_LOAD_ALL = "QUIZ_LOAD_ALL"
export const QUIZ_LOAD_DETAILS = "QUIZ_LOAD_DETAILS";
export const QUIZ_LOAD_ERROR = "QUIZ_LOAD_ERROR";
export const QUIZ_DELETE = "QUIZ_DELETE";


export const SHOW_QUIZS_MESSAGE = "SHOW_QUIZS_MESSAGE";
export const HIDE_QUIZ_MESSAGE = "HIDE_QUIZ_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const CREATE_QUIZ = "CREATE_QUIZ";
export const EDIT_QUIZ = "EDIT_QUIZ";


export const GET_QUIZ_DETAILS = 'GET_QUIZ_DETAILS';
export const SET_QUIZ_DETAILS = 'SET_QUIZ_DETAILS';
export const UPDATE_QUIZ = 'UPDATE_QUIZ';
export const UPDATE_QUIZ_DETAILS = 'UPDATE_QUIZ_DETAILS';

export const QUIZ_RANDOM_REWARD_USERS = 'QUIZ_RANDOM_REWARD_USERS';