import { combineReducers } from 'redux';
import Auth from './Auth';
import Courses from './Course';
import Lessons from './Lesson';
import Quiz from './Quiz';
import Question from './Question';
import Kpi from './Kpi';
import Crypto from './Crypto';
import Notification from './Notification';
import Reward from './Reward';
import Theme from './Theme';


const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    course: Courses,
    kpi: Kpi,
    lesson: Lessons,
    quiz: Quiz,
    question: Question,
    crypto: Crypto,
    notification: Notification,
    rewardsStore: Reward,

});

export default reducers;