import {
    QUIZ_LOAD,
    SHOW_LOADING,
    SHOW_QUIZS_MESSAGE,
    HIDE_QUIZ_MESSAGE,
    CREATE_QUIZ,
    EDIT_QUIZ,
    QUIZ_LOAD_ALL,
    QUIZ_DELETE,
    GET_QUIZ_DETAILS,
    SET_QUIZ_DETAILS,
    UPDATE_QUIZ,
    UPDATE_QUIZ_DETAILS,
    QUIZ_RANDOM_REWARD_USERS,
} from '../constants/Quiz';  
  export const get_QUIZs = (course_id) => {
    return {
        type: QUIZ_LOAD,
        payload: course_id
      }
  };

  export const delete_QUIZ = (id) => {
    return {
        type: QUIZ_DELETE,
        payload: id
      }
  };

  export const get_all_QUIZ = (QUIZs) => {
    return {
        type: QUIZ_LOAD_ALL,
        payload: QUIZs
      }
  };

  export const createQUIZ = (QUIZ) => {
    return {
        type: CREATE_QUIZ,
        payload: QUIZ
      }
  };

  export const editQUIZ = (QUIZ) => {
    return {
        type: EDIT_QUIZ,
        payload: QUIZ
      }
  };

  export const updateQUIZ = (QUIZ) => {
    return {
        type: UPDATE_QUIZ,
        payload: QUIZ
      }
  };

  export const rewardQuiz = (REWARD) => {
    return {
        type: QUIZ_RANDOM_REWARD_USERS,
        payload: REWARD
    }
  };

  export const getQUIZDetails = (QUIZ) => {
    return {
        type: GET_QUIZ_DETAILS,
        payload: QUIZ
      }
  };

  export const setQUIZDetails = (QUIZ) => {
    return {
        type: SET_QUIZ_DETAILS,
        payload: QUIZ
      }
  };

  export const showQUIZMessage = (message) => {
    return {
      type: SHOW_QUIZS_MESSAGE,
      message
    };
  };

  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideQUIZMessage = () => {
    return {
      type: HIDE_QUIZ_MESSAGE,
    };
  };

  