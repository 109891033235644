export const QUESTION_LOADING = "QUESTION_LOADING";
export const QUESTION_LOAD = "QUESTION_LOAD";
export const QUESTION_LOAD_ALL = "QUESTION_LOAD_ALL"
export const QUESTION_LOAD_DETAILS = "QUESTION_LOAD_DETAILS";
export const QUESTION_LOAD_ERROR = "QUESTION_LOAD_ERROR";
export const QUESTION_DELETE = "QUESTION_DELETE";


export const SHOW_QUESTIONS_MESSAGE = "SHOW_QUESTIONS_MESSAGE";
export const HIDE_QUESTION_MESSAGE = "HIDE_QUESTION_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const CREATE_QUESTION = "CREATE_QUESTION";
export const EDIT_QUESTION = "EDIT_QUESTION";


export const GET_QUESTION_DETAILS = 'GET_QUESTION_DETAILS';
export const SET_QUESTION_DETAILS = 'SET_QUESTION_DETAILS';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const UPDATE_QUESTION_DETAILS = 'UPDATE_QUESTION_DETAILS';