const {
	REACT_APP_BACKEND_URL_PROD,
	REACT_APP_BACKEND_URL_DEV,
	REACT_APP_BLACKAPPLE_BACKEND_URL_PROD
} = process.env;

const dev = {
  API_ENDPOINT_URL: REACT_APP_BACKEND_URL_PROD,
  BLACKAPPLE_API_ENDPOINT_URL: REACT_APP_BLACKAPPLE_BACKEND_URL_PROD
};

const prod = {
  API_ENDPOINT_URL: REACT_APP_BACKEND_URL_PROD,
  BLACKAPPLE_API_ENDPOINT_URL: REACT_APP_BLACKAPPLE_BACKEND_URL_PROD
};

const test = {
  API_ENDPOINT_URL: REACT_APP_BACKEND_URL_PROD,
  BLACKAPPLE_API_ENDPOINT_URL: REACT_APP_BLACKAPPLE_BACKEND_URL_PROD
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
