import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  QUIZ_LOAD,
  CREATE_QUIZ,
  QUIZ_DELETE,
  EDIT_QUIZ,
  GET_QUIZ_DETAILS,
  UPDATE_QUIZ,
  QUIZ_RANDOM_REWARD_USERS
} from '../constants/Quiz';
import {
    get_all_QUIZ,
    showQUIZMessage,
    setQUIZDetails,
    rewardQuiz
} from "../actions/Quiz";

import service from '../../auth/FetchInterceptor'

export function* getAllQUIZs() {
   
  yield takeEvery(QUIZ_LOAD, function* ({payload}) {
		
		try {

			const quiz = yield call(() => {

          return service().get(`course/lesson/quiz/all?lesson_id=${payload}`)
                
      });

      let {status, message, showQuiz, data} = quiz.data;

        if (!status) {

            yield put(get_all_QUIZ([]));
                    
        } else {

            yield put(get_all_QUIZ(data));
                    
        }
            
		} catch (err) {

            yield put(showQUIZMessage(err));
            
		}
	});
}

export function* createQUIZ() {
  yield takeEvery(CREATE_QUIZ, function* ({payload}) {
	
		try {

			const quiz = yield call(() => {

                return service().post("course/lesson/quiz/create",payload)
                
            });

            let {status, message, data} = quiz.data;

                alert(message);

			if (!status) {

                yield put(showQUIZMessage(message));
                
			} else {


          yield put(showQUIZMessage(message));
                
      }
            
		} catch (err) {
        
      yield put(showQUIZMessage(err.message));
            
		}
	});
}

export function* editQUIZ() {
  yield takeEvery(EDIT_QUIZ, function* ({payload}) {
	
		try {

    //   const config = {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // };

			const quiz = yield call(() => {

                return service().put("course/lesson/quiz/update",payload)
                
            });

            let {status, message, data} = quiz.data;

            alert(message);

			if (!status) {

                alert(message);

          yield put(showQUIZMessage(message));
                
			} else {

            alert('QUIZ Updated');

            yield put(showQUIZMessage(message));
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err));
        
      yield put(showQUIZMessage(err));
            
		}
	});
}

export function* deleteQUIZ() {
   
  yield takeEvery(QUIZ_DELETE, function* ({payload}) {
		
		try {

			const quiz = yield call(() => {

          return service().delete("course/lesson/quiz/delete", {data: {'quiz_id': payload}})
                
      });

      let {status, message, data} = quiz.data;

			if (!status) {
        alert(message)

          yield put(showQUIZMessage(message));
                
			} else {
        alert(message)
        
        yield put(showQUIZMessage(message));
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err));

            yield put(showQUIZMessage(err));
            
		}
	});
}

export function* getQUIZDetails() {
   
  yield takeEvery(GET_QUIZ_DETAILS, function* ({payload}) {
		
		try {

			const quiz = yield call(() => {

          return service().get(`course/quiz/${payload}`)
                
      });

      let {status, message, data} = quiz.data;

			if (!status) {

          yield put(showQUIZMessage(message));
                
			} else {

    
        yield put(setQUIZDetails(data));
                
      }
            
		} catch (err) {
        yield put(showQUIZMessage(err));
            
		}
	});
}

export function* updateQUIZ() {
  yield takeEvery(UPDATE_QUIZ, function* ({payload}) {
	
		try {

			const quiz = yield call(() => {

          return service().put("course/lesson/quiz/update",payload)
                
      });

      let {status, message, data} = quiz.data;

		if (!status) {

            yield put(showQUIZMessage(message));
                
		} else {

            yield put(showQUIZMessage(message));
                
        }
            
		} catch (err) {
        
            yield put(showQUIZMessage(err.message));
            
		}
	});
}

export function* randomReward() {
  yield takeEvery(QUIZ_RANDOM_REWARD_USERS, function* ({payload}) {
	
		try {

			const quiz = yield call(() => {

          return service().post("course/lesson/quiz/random-reward", payload)
                
      });

      let {status, message, data} = quiz.data;

		if (!status) {

            yield put(showQUIZMessage(message));
                
		} else {

            yield put(showQUIZMessage(message));
                
        }
            
		} catch (err) {
        
            yield put(showQUIZMessage(err.message));
            
		}
	});
}

export default function* rootSaga() {
  yield all([
        fork(getAllQUIZs),
        fork(createQUIZ),
        fork(deleteQUIZ),
        fork(editQUIZ),
        fork(getQUIZDetails),
        fork(updateQUIZ),
        fork(randomReward)
  ]);
}
