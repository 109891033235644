import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  COURSE_LOAD,
  CREATE_COURSE,
  COURSE_DELETE,
  EDIT_COURSE,
  GET_COURSE_DETAILS,
  UPDATE_COURSE
} from '../constants/Course';
import {
    get_all_course,
    showCourseMessage,
    createCourse,
    setCourseDetails
} from "../actions/Course";

import service from '../../auth/FetchInterceptor'

export function* getAllCourses() {
   
  yield takeEvery(COURSE_LOAD, function* () {
		
		try {

			const course = yield call(() => {

          return service().get("course/all")
                
      });

      let {status, message, data} = course.data;

			if (!status) {

          yield put(showCourseMessage(message));
                
			} else {
        yield put(get_all_course(data));
                
      }
            
		} catch (err) {
        yield put(showCourseMessage(err));
            
		}
	});
}

export function* createCourses() {
  yield takeEvery(CREATE_COURSE, function* ({payload}) {
	
		try {

    //   const config = {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // };

			const course = yield call(() => {

                return service().post("course/create",payload)
                
            });

            let {status, message, data} = course.data;

            alert(message);

			if (!status) {

          yield put(showCourseMessage(message));
                
			} else {

          alert('course added');

          yield put(showCourseMessage(message));
                
      }
            
		} catch (err) {
        
      yield put(showCourseMessage(err.message));
            
		}
	});
}

export function* editCourses() {
  yield takeEvery(EDIT_COURSE, function* ({payload}) {
	
		try {

    //   const config = {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // };

			const course = yield call(() => {

                return service().put("course/update",payload)
                
            });

            let {status, message, data} = course.data;

            alert(message);

			if (!status) {

          yield put(showCourseMessage(message));
                
			} else {

          alert('course Updated');

          yield put(showCourseMessage(message));
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err));
        
      yield put(showCourseMessage(err));
            
		}
	});
}

export function* deleteCourses() {
   
  yield takeEvery(COURSE_DELETE, function* ({payload}) {

    alert(payload);
		
		try {

			const course = yield call(() => {

          return service().delete("course/delete", {data: {'course_id': payload}})
                
      });

      let {status, message, data} = course.data;

			if (!status) {
        alert(message)

          yield put(showCourseMessage(message));
                
			} else {
        alert(message)
        
        yield put(showCourseMessage(message));
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err));

            yield put(showCourseMessage(err));
            
		}
	});
}

export function* getCourseDetails() {
   
  yield takeEvery(GET_COURSE_DETAILS, function* ({payload}) {
		
		try {

			const course = yield call(() => {

          return service().get(`course/${payload}`)
                
      });

      let {status, message, data} = course.data;

			if (!status) {

          yield put(showCourseMessage(message));
                
			} else {
    
        yield put(setCourseDetails(data));
                
      }
            
		} catch (err) {
        yield put(showCourseMessage(err));
            
		}
	});
}

export function* updateCourse() {
  yield takeEvery(UPDATE_COURSE, function* ({payload}) {
	
		try {

			const course = yield call(() => {

          return service().put("course/update",payload)
                
      });

      let {status, message, data} = course.data;

      alert(message);

			if (!status) {

          yield put(showCourseMessage(message));
                
			} else {

          yield put(showCourseMessage(message));
                
      }
            
		} catch (err) {
        
      yield put(showCourseMessage(err.message));
            
		}
	});
}

export default function* rootSaga() {
  yield all([
        fork(getAllCourses),
        fork(createCourses),
        fork(deleteCourses),
        fork(editCourses),
        fork(getCourseDetails),
        fork(updateCourse)
  ]);
}
