import {
	SHOW_LOADING,
	REWARDS_LOAD_ALL,
    REWARDS_DELETE,
    CREATE_REWARD
} from '../constants/Reward';

const initState = {
  loading: false,
  rewards:[],
}

const Reward = (state = initState, action) => {
	switch (action.type) {

		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}

		/////// Rewards ///////////

		case REWARDS_LOAD_ALL:
			return {
				...state,
				loading: false,
				rewards: action.payload
			}

		default:
			return state;
	}
}

export default Reward;