import React, {
    Component,
    useEffect,
    useState
} from "react";
import jwt_decode from 'jwt-decode';
import { connect } from "react-redux";
import { refreshToken as refresh_token } from "../redux/actions/Auth";
import { refreshtoken } from "../redux/sagas/Auth";
import isAuthenticated   from "./isAuthenticated";
let refreshInterval;
export let Refresh = props => {
  let { 
    token,
    refresh_token
  } = props
  const [intervalId, setIntervalId] = useState(null);
      const refreshMethod = () => {
        console.log('read');
        let tokenFunc =  token || localStorage.token;
        if(tokenFunc){
          let jwt = jwt_decode(tokenFunc, {headers: true});
          let current_time = Date.now().valueOf();
          const jwtExp = jwt.exp - current_time ;
          console.log(jwtExp);
          console.log(jwtExp < 200000);

          if(jwtExp < 200000){
            refresh_token();
          }
        }
      }
    
      useEffect(() => {
        if(token || localStorage.token){
          if(!refreshInterval){
            refreshInterval = setInterval(refresh_token , 120000)
          }
        }else{ 
          if(refreshInterval){
            clearInterval(refreshInterval);
            refreshInterval = null;
          }
        }
      },[token])

      console.log("interval", refreshInterval);

      return null;
}

export class Basic extends Component {
	render() {
		return (
			<Refresh {...this.props}/>
		)
	}
}

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
}

const mapDispatchToProps = {
	refresh_token
}

export default connect(mapStateToProps, mapDispatchToProps)(Basic)
// export default () => <Refresh refresh_token={refreshtoken} token={localStorage.token} />