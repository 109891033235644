import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  LESSON_LOAD,
  CREATE_LESSON,
  LESSON_DELETE,
  EDIT_LESSON,
  GET_LESSON_DETAILS,
  UPDATE_LESSON
} from '../constants/Lesson';
import {
    get_all_LESSON,
    showLESSONMessage,
    setLESSONDetails
} from "../actions/Lesson";

import service from '../../auth/FetchInterceptor'

export function* getAllLessons() {
   
  yield takeEvery(LESSON_LOAD, function* ({payload}) {
		
		try {

			const course = yield call(() => {

          return service().get(`course/lesson/all?course_id=${payload}&&published=any`)
                
      });

      let {status, message, data} = course.data;

        if (!status) {

            yield put(showLESSONMessage(message));
                    
        } else {

            yield put(get_all_LESSON(data));
                    
        }
            
		} catch (err) {

            yield put(showLESSONMessage(err));
            
		}
	});
}

export function* createLesson() {
  yield takeEvery(CREATE_LESSON, function* ({payload}) {
	
		try {

			const course = yield call(() => {

                return service().post("course/lesson/create",payload)
                
            });

            let {status, message, data} = course.data;

                alert(message);

			if (!status) {

                yield put(showLESSONMessage(message));
                
			} else {


          yield put(showLESSONMessage(message));
                
      }
            
		} catch (err) {
        
      yield put(showLESSONMessage(err.message));
            
		}
	});
}

export function* editLesson() {
  yield takeEvery(EDIT_LESSON, function* ({payload}) {
	
		try {

    //   const config = {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // };

			const course = yield call(() => {

                return service().put("course/lesson/update",payload)
                
            });

            let {status, message, data} = course.data;

            alert(message);

			if (!status) {

                alert(message);

          yield put(showLESSONMessage(message));
                
			} else {

            alert('Lesson Updated');

            yield put(showLESSONMessage(message));
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err));
        
      yield put(showLESSONMessage(err));
            
		}
	});
}

export function* deleteLesson() {
   
  yield takeEvery(LESSON_DELETE, function* ({payload}) {
		
		try {

			const course = yield call(() => {

          return service().delete("course/lesson/delete", {data: {'lesson_id': payload}})
                
      });

      let {status, message, data} = course.data;

			if (!status) {
        alert(message)

          yield put(showLESSONMessage(message));
                
			} else {
        alert(message)
        
        yield put(showLESSONMessage(message));
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err));

            yield put(showLESSONMessage(err));
            
		}
	});
}

export function* getLessonDetails() {
   
  yield takeEvery(GET_LESSON_DETAILS, function* ({payload}) {

		
		try {

			const course = yield call(() => {

          return service().get(`course/lesson/${payload}`)
                
      });

      let {status, message, data} = course.data;

			if (!status) {

          yield put(showLESSONMessage(message));
                
			} else {

    
        yield put(setLESSONDetails(data));
                
      }
            
		} catch (err) {
        yield put(showLESSONMessage(err));
            
		}
	});
}

export function* updateLesson() {
  yield takeEvery(UPDATE_LESSON, function* ({payload}) {
	
		try {

			const course = yield call(() => {

          return service().put("course/lesson/update",payload)
                
      });

      let {status, message, data} = course.data;

		if (!status) {

          yield put(showLESSONMessage(message));
                
		} else {

          yield put(showLESSONMessage(message));
                
      }
            
		} catch (err) {
        
      yield put(showLESSONMessage(err.message));
            
		}
	});
}

export default function* rootSaga() {
  yield all([
        fork(getAllLessons),
        fork(createLesson),
        fork(deleteLesson),
        fork(editLesson),
        fork(getLessonDetails),
        fork(updateLesson)
  ]);
}
