import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  QUESTION_LOAD,
  CREATE_QUESTION,
  QUESTION_DELETE,
  EDIT_QUESTION,
  GET_QUESTION_DETAILS,
  UPDATE_QUESTION
} from '../constants/Question';
import {
    get_all_QUESTION,
    showQUESTIONMessage,
    setQUESTIONDetails
} from "../actions/Question";

import service from '../../auth/FetchInterceptor'

export function* getAllQUESTIONs() {
   
  yield takeEvery(QUESTION_LOAD, function* ({payload}) {
		
		try {

			const QUESTION = yield call(() => {

          return service().get(`course/lesson/quiz/question/all?quiz_id=${payload}`)
                
      });

      let {status, message, data} = QUESTION.data;

        if (!status) {

            yield put(get_all_QUESTION([]));
                    
        } else {

            yield put(get_all_QUESTION(data));
                    
        }
            
		} catch (err) {

            yield put(showQUESTIONMessage(err));
            
		}
	});
}

export function* createQUESTION() {
  yield takeEvery(CREATE_QUESTION, function* ({payload}) {
	
		try {

			const QUESTION = yield call(() => {

                return service().post("course/lesson/quiz/question/create",payload)
                
            });

            let {status, message, data} = QUESTION.data;

                alert(message);

			if (!status) {

                yield put(showQUESTIONMessage(message));
                
			} else {


          yield put(showQUESTIONMessage(message));
                
      }
            
		} catch (err) {
        
      yield put(showQUESTIONMessage(err.message));
            
		}
	});
}

export function* editQUESTION() {
  yield takeEvery(EDIT_QUESTION, function* ({payload}) {
	
		try {

    //   const config = {
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // };

			const QUESTION = yield call(() => {

                return service().put("course/lesson/quiz/question/update",payload)
                
            });

            let {status, message, data} = QUESTION.data;

            alert(message);

			if (!status) {

                alert(message);

          yield put(showQUESTIONMessage(message));
                
			} else {

            alert('QUESTION Updated');

            yield put(showQUESTIONMessage(message));
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err));
        
      yield put(showQUESTIONMessage(err));
            
		}
	});
}

export function* deleteQUESTION() {
   
  yield takeEvery(QUESTION_DELETE, function* ({payload}) {
		
		try {

			const QUESTION = yield call(() => {

          return service().delete("course/lesson/quiz/question/delete", {data: {'quiz_question_id': payload}})
                
      });

      let {status, message, data} = QUESTION.data;

			if (!status) {
        alert(message)

          yield put(showQUESTIONMessage(message));
                
			} else {
        alert(message)
        
        yield put(showQUESTIONMessage(message));
                
      }
            
		} catch (err) {

      alert(JSON.stringify(err));

            yield put(showQUESTIONMessage(err));
            
		}
	});
}

export function* getQUESTIONDetails() {
   
  yield takeEvery(GET_QUESTION_DETAILS, function* ({payload}) {

		
	try {

		const QUESTION = yield call(() => {

          return service().get(`course/lesson/quiz/question/${payload}`)
                
        });

        let {status, message, data} = QUESTION.data;

		if (!status) {

          yield put(showQUESTIONMessage(message));
                
		} else {

    
          yield put(setQUESTIONDetails(data));
                
        }
            
		} catch (err) {

            yield put(showQUESTIONMessage(err));
            
		}
	});
}

export function* updateQUESTION() {
        yield takeEvery(UPDATE_QUESTION, function* ({payload}) {
	
		try {

			const QUESTION = yield call(() => {

          return service().put("course/lesson/quiz/question/update",payload)
                
        });

        let {status, message, data} = QUESTION.data;

		if (!status) {

            yield put(showQUESTIONMessage(message));
                
		} else {

            yield put(setQUESTIONDetails(payload));

            yield put(showQUESTIONMessage(message));
                
        }
            
		} catch (err) {
        
            yield put(showQUESTIONMessage(err.message));
            
		}
	});
}

export default function* rootSaga() {
  yield all([
        fork(getAllQUESTIONs),
        fork(createQUESTION),
        fork(deleteQUESTION),
        fork(editQUESTION),
        fork(getQUESTIONDetails),
        fork(updateQUESTION)
  ]);
}
